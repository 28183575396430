export default function about_us() {
    const c = 'background: linear-gradient(to bottom right, #7b2a52, #36307f,#272151);color:#fff';
    console.log('%c Sakurairo %c https://github.com/mirai-mamori/Sakurairo', c, '');
    console.log('誰もが信じ崇めてる\n' +
        'まさに最強で無敵のアイドル\n' +
        '弱点なんて見当たらない\n' +
        '一番星を宿している\n' +
        '弱いとこなんて見せちゃダメダメ\n' +
        '知りたくないとこは見せずに\n' +
        '唯一無二じゃなくちゃイヤイヤ\n' +
        'それこそ%c本物のアイ%c\n' +
        '%c ⌜IDOL⌟ ', 'color:#b424b8', '', c);
}
