import { awaitImage, readImageDownsampling, } from '@kotorik/palette';
import PromiseWorker from 'promise-worker';
import { isInDarkMode } from '../darkmode';
//@ts-ignore
import 'color-space/hsl';
//@ts-ignore
import rgb from 'color-space/rgb';
let worker;
let currentColor;
function hslaCSSText([h, s, l, a]) {
    if (a) {
        return "hsla(" + h + "deg," + s + "%," + l + "%," + a + ")";
    }
    else {
        return "hsl(" + h + "deg," + s + "%," + l + "%)";
    }
}
export async function updateThemeSkin(coverBGUrl) {
    try {
        const imgElement = document.createElement('img');
        imgElement.src = coverBGUrl;
        imgElement.crossOrigin = "anonymous";
        await awaitImage(imgElement);
        const data = readImageDownsampling(imgElement, 10000);
        const result = await worker.postMessage({
            k: 8,
            //iteration: 20,
            img: data
        });
        const { label, centroid } = result;
        const max = Math.max(...label);
        const index = label.findIndex(value => value == max);
        currentColor = centroid[index];
        _setColor();
    }
    catch (error) {
        console.error(error);
        _updateThemeColor(getComputedStyle(document.documentElement).getPropertyValue('--theme-skin-matching')); //回滚
    }
}
function _setColor(darkmode) {
    const hsla = rgb.hsl(currentColor);
    if (typeof darkmode == 'undefined' ? isInDarkMode() : darkmode)
        hsla[2] *= 0.5;
    /*     const textColor = [0, 0, 0] as [number, number, number]
    
        if (hsla[2] > 40) {
            textColor[2] = 0.314
        } else {
            textColor[2] = 1 - 0.314
        }
    
        const style = document.documentElement.style
        const [h, s, l] = hsla
        style.setProperty('--header-color-h', h + 'deg')
        style.setProperty('--header-color-s', s + '%')
        style.setProperty('--header-color-l', l + '%')
    
        const siteHeader = document.querySelector<HTMLDivElement>('.site-header')
        siteHeader.style.color = hslaCSSText(textColor)
     */
    _updateThemeColor(hslaCSSText(hsla));
}
function _updateThemeColor(color_css) {
    const meta = document.querySelector('meta[name=theme-color]');
    meta && (meta.content = color_css);
}
export function initThemeColor() {
    if (!worker)
        worker = new PromiseWorker(new Worker(new URL('./worker.ts', import.meta.url)));
    document.addEventListener('coverBG_change', (({ detail: coverBGUrl }) => updateThemeSkin(coverBGUrl)));
    document.addEventListener('darkmode', (({ detail: next }) => _setColor(next)));
}
