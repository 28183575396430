import { createStore, promisifyRequest } from 'idb-keyval';
const store = createStore('sakurairo', 'cache');
const get = (key) => store('readonly', (store) => promisifyRequest(store.get(key)));
const set = (key, value) => store('readwrite', (store) => {
    store.put(value, key);
    return promisifyRequest(store.transaction);
});
const del = (key) => store('readwrite', (store) => {
    store.delete(key);
    return promisifyRequest(store.transaction);
});
export { get, set, del };
