/**
 *
 * @param key 这段句子的中文表述（默认语言）
 */
//@ts-ignore
export const __ = (defaultStr) => (window._sakurairoi18n && _sakurairoi18n[defaultStr]) || defaultStr;
/**
 *
 * 占位符格式 {index} ex.{0}
 * @param defaultStr 这段句子的中文表述（默认语言）
 * @param replacements 句子中占位符的替代元素
 */
export const _$ = (defaultStr, ...replacements) => {
    let template = __(defaultStr);
    for (const index in replacements) {
        template = template.replace('{' + index + '}', replacements[index]);
    }
    return template;
};
export const sakurairoGlobal = {
    opt: {},
    /*     _sys_call:sys_call
     */
    build: BUILD_INFO
};
/**
 * 在sakurairoGlobal注册一个对象
 * @param key
 * @param value
 * @returns
 */
export const registerOnGlobal = (key, value) => Object.defineProperty(sakurairoGlobal, key, {
    value: value,
    writable: false,
    enumerable: true,
});
if ('_sakurairo' in window) {
    //@ts-ignore
    const _sakurairo = window._sakurairo;
    //@ts-ignore
    if ('opt' in _sakurairo) {
        sakurairoGlobal.opt = _sakurairo.opt;
    }
}
//@ts-ignore
window._sakurairo = sakurairoGlobal;
